import * as React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";

const TechnologyPage = () => {
  return (
    <Layout>
      <div className="top_section">
        <div className="bg tech"></div>
        <div className="inner_sub">
          <section id="technology"></section>
          <div className="top_text">Technology</div>
          <p>
            We have various technical expertise which provides a web-based full
            3D visualization technology and a proprietary big data processing
            system, which can be immediately used in construction and
            operational asset industry.
          </p>
        </div>
      </div>
      <div className="content">
        <div className="sub_section">
          <div className="main_section">
            <div style={{ padding: 0 }}>
              <p>
                <strong style={{ fontWeight: "bold" }}>Conworth Engine</strong>{" "}
                : 3D data/rendering engine for high-performance scalable server
                operation and massive 3D data rendering.
              </p>
              <div
                className="tech__conworth_engine"
                // style={{ display: "block", margin: "0px auto", width: 1280 }}
              >
                <center>
                <StaticImage src={"../images/tech__conworth_engine.png"} />
                </center>
              </div>
              <p>
                <strong style={{ fontWeight: "bold" }}>
                  Massive 3D Rendering Test
                </strong>{" "}
                : Conworth engine is particularly specialized for massive
                computation and high-quality rendering for massive 3D data.
              </p>
              <br />
              <div
                className="tech__massive3d_test"
                // style={{ display: "block", margin: "0px auto", width: 1280 }}
              >
                <StaticImage src={"../images/img_tech_massive3d.png"} />
              </div>
              <p>
                <strong style={{ fontWeight: "bold" }}>
                  Digital twinning technology
                </strong>
                : Integrated with proprietary big data processing system, robot,
                IoT, BIM and point cloud.
              </p>
            </div>
          </div>
          <div>
            <ul className="tech_full">
              <li>
                <span className="thumb">
                  <StaticImage src="../images/img_tech1.png" alt="" />
                </span>
                <div className="info_box">
                  <strong className="tit">Full 3D</strong>
                  <p>
                    We deliver and manage seamless indoor-outdoor full 3D data
                    for industrial sites.
                  </p>
                  <span>
                    BIM, Pointcloud, 360 images, IoT, resource trajectory are
                    fully supported in our data platforms.
                  </span>
                </div>
              </li>
              <li>
                <span className="thumb">
                  <StaticImage src="../images/img_tech2.png" alt="" />
                </span>
                <div className="info_box">
                  <strong className="tit">High Performance Computing</strong>
                  <p>
                    Compared with commercial cloud system, our proprietary edge
                    cloud system offers low-cost operative and scalable
                    solution.
                  </p>
                  <span>
                    Economic Hadoop clusters provide big data management and
                    processing ability, and advances in computational power.
                  </span>
                </div>
              </li>
              <li>
                <span className="thumb">
                  <StaticImage src="../images/img_tech3.png" alt="" />
                </span>
                <div className="info_box">
                  <strong className="tit">Web/App-Based Platform</strong>
                  <p>
                    Our web/app-based data platform can be used as an efficient
                    communication and collaboration tool in construction sites
                    and physical asset management.
                  </p>
                  <span>
                    A web/app-based service overcomes the physical restriction
                    for information sharing, and requires fewer hardware
                    resources in comparison with stand-alone solution.
                  </span>
                </div>
              </li>
              <li>
                <span className="thumb">
                  <StaticImage src="../images/img_tech4.png" alt="" />
                </span>
                <div className="info_box">
                  <strong className="tit">Robot Integration</strong>
                  <p>
                    Integration of cutting-edge robot technology with our data
                    platform opens a new horizon of remote monitoring of
                    construction sites and physical assets.
                  </p>
                  <span>
                    Boston Dynamics Spot® is fully integrated with our data
                    platform, which can provide remote control, portable IOT
                    access, and autonomous monitoring.
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TechnologyPage;
